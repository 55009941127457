import React from 'react'
import style from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { FaEnvelope, FaHome, FaIdCard, FaMandalorian, FaMapPin, FaPhone, FaUser } from 'react-icons/fa'

const AboutSection = style.section`
  max-width: 30em;
  align-self: flex-start;
  padding: 1em;
  margin: auto;
  margin-top: 2%;
  text-align: left;
  font-size: 1.2em;
  text-align: justify;
`

const GroupPhoto = style(Img)`
  width: 100%;
  max-width: 20em;
  margin: 1.5em auto 1.5em auto;
  border: 0px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 5px gray;
`

const ContactsContainer = style.div`
  display: grid;
  padding-left: 2em;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(5, 1.25em);
  grid-gap: .5em;
  justify-items: stretch;
  align-items: center;
`;

const AboutPage = () => {
  const GroupImageData = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "group_photo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return (
    <Layout colorful={true}>
      <SEO title="Chi siamo" />
      <AboutSection>
        <h1>Chi siamo?</h1>
        Siamo una associazione benefica nata in memoria di Andrea Biondi, con lo
        scopo di aiutare, tramite eventi e donazioni, i pazienti pediatrici (e
        le loro famiglie) che si trovano nella stessa situazione affrontata da
        Andrea.
        <h3>Eccoci qui...</h3>
        <GroupPhoto
          fluid={GroupImageData.placeholderImage.childImageSharp.fluid}
        />
        Se vuoi leggere di piú su di noi o scoprire come utiliziamo le vostre
        donazioni puoi leggere il nostro <Link to="/blog/">blog</Link> e
        visitare la pagina per le <Link to="/donazioni/">donazioni</Link> (dove
        troverai tutte le informazioni per aiutarci).
        <h2>Contatti ed altre informazioni</h2>
        <ContactsContainer>
          <FaUser/><p><strong>Amici di Andrea</strong> - <em>ODV</em></p>
          <FaMapPin/><p>Via della Valle, 10 - Scandriglia (RI)</p>
          <FaIdCard/><p><em>C.F.</em> <strong>90080690572</strong></p>
          <FaPhone/><p><a href="tel:+393286124756">+39 328 6124756</a> <em>(Eleonora)</em></p>
          <FaEnvelope/>
          <p>
            <a href="mailto:amicidiandrea@gmail.com">mail@amicidiandrea.org</a>
          </p>
        </ContactsContainer>
        {/* rgb(153, 186, 247) */}
      </AboutSection>
    </Layout>
  )
}

export default AboutPage
